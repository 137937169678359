<script lang="ts">
export default {
  name: 'SurveyQuestion',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
import { defineProps, defineEmits, ref, Ref, onMounted } from 'vue';
import { SurveyQuestion, SurveyAnswer } from '@/types/Survey';
import { useI18n } from '@/composables/useI18n';
import { useNoUiSlider } from '@/composables/useNoUiSlider';
import TextArea from '@/components/atoms/forms/TextArea/Index.vue';
import TransitionSlide from '@/components/atoms/Transitions/SlideX.vue';
import InputHint from '@/components/atoms/forms/InputHint/Index.vue';
import noUiSlider from 'nouislider';

const props = defineProps<{
  modelValue: SurveyAnswer;
  question: SurveyQuestion;
  inputId: string;
  disabled?: boolean;
  error?: string;
}>();
const emit = defineEmits<{
  (e: 'update:model-value', value: SurveyAnswer): void;
}>();

const { t } = useI18n();

const onSliderInput = (value: string[]) => {
  if (value?.length) {
    const payload = {
      ...props.modelValue,
      opinion_scale_score: Number(value[0]),
    };
    emit('update:model-value', payload);
  }
};

const onTextInput = (answer: string) => {
  const payload = {
    ...props.modelValue,
    answer,
  };
  emit('update:model-value', payload);
};

const slider: Ref<HTMLElement | null> = ref(null);
const noUiSliderInstance: Ref<noUiSlider | null> = ref(null);

onMounted(() => {
  if (slider.value) {
    noUiSliderInstance.value = useNoUiSlider(slider.value, {
      start: props.modelValue?.opinion_scale_score || props.question.opinion_scale_min,
      min: props.question.opinion_scale_min,
      max: props.question.opinion_scale_max,
    });

    noUiSliderInstance.value?.on('change', onSliderInput);
  }
});
</script>

<template>
  <div v-bind="$attrs" ref="slider" class="survey-question-slider-input" />
  <TransitionSlide>
    <div v-if="question.opinion_scale_optional_free_text && modelValue?.opinion_scale_score">
      <label :for="inputId">{{ t('surveys.answer_questions.fields.please_elaborate') }}</label>
      <TextArea
        :id="inputId"
        :model-value="modelValue?.answer"
        class="survey-question-slider-input__text"
        data-test-id="survey-question-slider-text-input"
        @update:model-value="onTextInput"
      />
    </div>
  </TransitionSlide>
  <InputHint v-if="error" :value="error" :error="!!error" class="m-t-1" />
</template>

<style lang="scss" scoped>
.survey-question-slider-input__text {
  display: block;
  width: 100%;
}
</style>
