<template>
  <form :id="componentId" class="base-note-input">
    <template v-if="showPrivacyOptions && updatedNote">
      <ButtonRadioInput
        v-model:model-value="updatedNote.private"
        :disabled="disabled"
        :options="[
          {
            label: t('vue_templates.notes.button.private'),
            value: true,
          },
          {
            label: t('vue_templates.notes.button.public'),
            value: false,
          },
        ]"
        class="base-note-input__private-input"
        name="private"
        rounded
        small
      />
      <small v-if="privacyTypeHint" class="base-note-input__private-input-hint text-muted m-b-1">
        {{ privacyTypeHint }}
      </small>
    </template>
    <RichTextInput
      v-if="updatedNote"
      id="note-input"
      v-model:model-value="updatedNote.content"
      :init-config-type="TinyMceConfigTypes.notes"
      :disabled="disabled"
      :at-mention-params="atMentionParams"
      :editor-config="editorConfig"
      @tinymce-dialog-opened="handleTinymceDialogOpened"
      @tinymce-dialog-closed="handleTinymceDialogClosed"
    />
    <TransitionSwap direction="down">
      <div v-if="hasAttachment" class="base-note-input__attachment-wrapper">
        <span
          >{{ t('vue_templates.components.base_note.attachment') }}:
          <span class="base-note-input__attachment-name">{{ attachmentName }}</span></span
        >
        <BaseButton
          v-tippy="tippy(t('vue_templates.components.base_note.remove_attachment'))"
          variant="icon"
          theme="info"
          prepend-icon="trash"
          :aria-label="t('vue_templates.components.base_note.remove_attachment')"
          type="button"
          data-test-id="base-note-input-remove-attachment"
          class="base-note-input__remove-attachment"
          @click="onRemoveAttachment"
        />
      </div>
      <div v-else-if="allowAttachments" class="base-note-input__attachment-buttons">
        <BaseButton
          v-tippy="tippy(t('vue_templates.components.base_note.attach_file'))"
          variant="icon"
          theme="info"
          size="large"
          prepend-icon="paperclip"
          :aria-label="t('vue_templates.components.base_note.attach_file')"
          type="button"
          data-test-id="base-note-input-file-upload"
          :disabled="isUploading"
          @click="showFileInput = !showFileInput"
        />
        <BaseButton
          v-tippy="tippy(t('vue_templates.components.base_note.attach_photo'))"
          variant="icon"
          theme="info"
          size="large"
          prepend-icon="image-photo"
          :aria-label="t('vue_templates.components.base_note.attach_photo')"
          type="button"
          data-test-id="base-note-input-photo-upload"
          :disabled="isUploading"
          @click="showFileInput = !showFileInput"
        />
      </div>
    </TransitionSwap>
    <TransitionSwap direction="down">
      <div v-if="showFileInput" class="base-note-input__attachment-input-wrapper">
        <TransitionSwap direction="down">
          <div v-if="isUploading" class="base-note-input__attachment-progress-wrapper">
            <ProgressBar
              :value="uploadProgress"
              :display-value="false"
              class="base-note-input__attachment-progress"
            />
            <BaseButton
              v-tippy="
                tippy(t('vue_templates.components.base_note.cancel_upload'), { placement: 'left' })
              "
              variant="icon"
              prepend-icon="error-cross"
              theme="error"
              class="m-l-2"
              @click="cancelUpload"
            />
          </div>
          <div v-else>
            <label class="visually-hidden" :for="`${componentId}-file-upload`">
              {{ t('vue_templates.components.base_note.upload_file') }}
            </label>
            <input
              :id="`${componentId}-file-upload`"
              type="file"
              class="base-note-input__attachment-input"
              data-test-id="base-note-input-file-input"
              @change="onFileAdd"
            />
          </div>
        </TransitionSwap>
      </div>
    </TransitionSwap>
    <div class="base-note-input__footer">
      <BaseButton
        :disabled="disabled"
        :text="t('vue_templates.components.base_note.cancel')"
        data-test-id="base-note-input-cancel"
        type="button"
        theme="learnamp"
        variant="plain"
        @click="onCancelClick"
      />
      <BaseButton
        :disabled="disabled || hasNoChanges"
        :text="saveButtonText"
        data-test-id="base-note-input-save"
        type="button"
        theme="learnamp"
        @click="onSaveClick"
      />
    </div>
  </form>
</template>

<script lang="ts">
export default {
  name: 'BaseNoteInput',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3 },
};
</script>

<script setup lang="ts">
// Modules
import { withDefaults, defineProps, defineEmits, ref, Ref, computed, onMounted, watch } from 'vue';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { notify } from '@kyvg/vue3-notification';
// Components
import BaseButton from '@/components/atoms/BaseButton/Index.vue';
import RichTextInput from '@/components/atoms/forms/RichTextInput/Index.vue';
import ButtonRadioInput from '@/components/molecules/forms/ButtonRadioInput/Index.vue';
import TransitionSwap from '@/components/atoms/Transitions/Swap.vue';
import ProgressBar from '@/components/atoms/ProgressBar/Index.vue';
// Composables
import { useI18n } from '@/composables/useI18n';
import { useApiClient } from '@/composables/useApiClient';
import { useDirectFileUpload, S3HostData } from '@/composables/useDirectFileUpload';
// Types
import INote, { INoteDraft, DocumentAttributesDestroy } from '@/types/Note';
import { ITinyMceAtMentionProps } from '@/composables/useAtMention';
import {
  CopyProps,
  IPropTypes,
} from '@tinymce/tinymce-vue/lib/cjs/main/ts/components/EditorPropTypes';
import { TinyMceConfigTypes } from '@/configs/tinymce';

interface Props {
  editorConfig?: CopyProps<IPropTypes>;
  atMentionParams?: ITinyMceAtMentionProps;
  note?: INote | INoteDraft;
  disabled?: boolean;
  showPrivacyOptions?: boolean;
  saveButtonText?: string;
  allowAttachments?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  allowAttachments: true,
});
const emit = defineEmits(['save', 'cancel', 'tinymce-dialog-opened', 'tinymce-dialog-closed']);

const { t } = useI18n();
const { apiClient } = useApiClient();
const { upload, getS3HostData, isUploading, uploadProgress, cancelUpload, isCancelled } =
  useDirectFileUpload(apiClient, t);
const updatedNote: Ref<INote | INoteDraft | undefined> = ref(_.cloneDeep(props.note));
const showFileInput = ref(false);
const s3HostData: Ref<S3HostData | undefined> = ref(undefined);
const componentId = uuidv4();

watch(
  () => props.note,
  (note) => {
    updatedNote.value = _.cloneDeep(note);
  }
);

const privacyTypeHint = computed(() => {
  switch (updatedNote.value?.private) {
    case true:
      return t('vue_templates.notes.help.note');
    case false:
      return t('vue_templates.notes.help.comment');
    default:
      return '';
  }
});

const handleTinymceDialogOpened = () => {
  emit('tinymce-dialog-opened');
};

const handleTinymceDialogClosed = () => {
  emit('tinymce-dialog-closed');
};

const hasNoChanges = computed(() => {
  const initialNote = _.omit(props.note, 'private');
  const updatedNoteValue = _.omit(updatedNote.value, 'private');
  return _.isEqual(initialNote, updatedNoteValue);
});

const hasAttachment = computed(() => {
  return (
    updatedNote.value?.document_attributes?.document_url ||
    updatedNote.value?.document?.document_url
  );
});

const attachmentName = computed(() => {
  if (updatedNote.value?.document_attributes?.document_url) {
    return _.truncate(updatedNote.value?.document_attributes.original_filename, { length: 30 });
  } else if (updatedNote.value?.document?.document_url) {
    return _.truncate(updatedNote.value.document.original_filename, { length: 30 });
  }
  return '';
});

const onCancelClick = () => {
  emit('cancel', updatedNote.value);
};

const onSaveClick = async () => {
  emit('save', updatedNote.value);
};

const onRemoveAttachment = () => {
  if (updatedNote.value) {
    if (props.note?.document) {
      updatedNote.value.document = null;
      (updatedNote.value.document_attributes as DocumentAttributesDestroy) = {
        _destroy: true,
      };
    } else {
      updatedNote.value.document_attributes = null;
    }
  }
};

const tippy = (content, options = {}) => ({
  ...options,
  content,
});

const onFileAdd = async (e) => {
  const file = e.target.files[0];

  if (!s3HostData.value) {
    notify({
      type: 'error',
      title: t('vue_templates.components.base_note.unable_to_upload_attachment'),
      text: t('vue_templates.composables.use_direct_file_upload.s3_data_not_available'),
    });
    return;
  }

  try {
    const response = await upload(s3HostData.value, file);
    if (!updatedNote.value || !response) {
      notify({
        type: 'error',
        title: t('vue_templates.components.base_note.unable_to_upload_attachment'),
      });
      return;
    }

    updatedNote.value.document = null;
    updatedNote.value.document_attributes = {
      document_url: response.url,
      file_type: response.type,
      file_size: response.size,
      original_filename: response.name,
    };
    showFileInput.value = false;
  } catch (e) {
    if (isCancelled.value) {
      notify({
        type: 'warning',
        title: t('vue_templates.components.base_note.upload_cancelled'),
      });
      return;
    }
    const error = typeof e === 'string' ? e : (e as Error)?.message;
    notify({
      type: 'error',
      title: t('vue_templates.components.base_note.unable_to_upload_attachment'),
      text: error,
    });
  }
};

onMounted(async () => {
  try {
    s3HostData.value = await getS3HostData();
  } catch (error) {
    console.warn('Unable to retrieve S3 host data');
  }
});
</script>

<style lang="scss" scoped>
.base-note-input__footer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.base-note-input__private-input {
  display: inline-block;
}

.base-note-input__private-input-hint {
  display: block;
}

.base-note-input__attachment-buttons {
  margin-top: 1.5rem;
}

.base-note-input__attachment-wrapper {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
}

.base-note-input__attachment-input-wrapper {
  margin-top: 1.5rem;
}

.base-note-input__attachment-progress-wrapper {
  display: flex;
  align-items: center;
}

.base-note-input__attachment-progress {
  flex-grow: 1;
}

.base-note-input__attachment-name {
  font-weight: 600;
}

.base-note-input__remove-attachment {
  margin-left: 1rem;
}
</style>
