<script lang="ts">
export default {
  name: 'DataFilterSegmentDropdown',
  // Remove when all components migrated to Vue 3.
  compatConfig: { MODE: 3, ATTR_FALSE_VALUE: false },
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
// Modules
import { defineProps, defineEmits, onMounted, ref, computed, nextTick, Ref, watch } from 'vue';
import _ from 'lodash';
import { onClickOutside } from '@vueuse/core';
// // Components
import BaseButton from '@/components/atoms/Button/Index.vue';
import LoadingSpinner from '@/components/atoms/LoadingSpinner/Index.vue';
import TransitionFade from '@/components/atoms/Transitions/Fade.vue';
import SearchInput from '@/components/atoms/SearchInput/Index.vue';
// Composables
import { useApiClient } from '@/composables/useApiClient';
import { useI18n } from '@/composables/useI18n';
// Types
import {
  SegmentParams,
  SegmentFilter,
  SegmentFilterArrayOption,
  SegmentOption,
  SegmentType,
  TeamAdditionalOption,
  ManagedAdditionalOption,
} from '@/types/DataFilter';

const { apiClient } = useApiClient();
const { t } = useI18n();
const props = defineProps<{
  filter: SegmentFilter[];
  active?: boolean;
  disabled?: boolean;
  initSegment: string;
  initSelectedTeams?: Array<string>;
  initSelectedGroups?: Array<string>;
  initIncludeSubTeams?: boolean;
  initPrimaryTeamMembersOnly?: boolean;
  initSelectedManager?: string;
  initIncludeSubmanagers?: boolean;
  teamPage?: boolean;
  groupPage?: boolean;
}>();

const emit = defineEmits(['update:modelValue']);

const getSelectedSegment = (): SegmentFilter => {
  return (
    props.filter.find((option) => option.segment === props.initSegment) ||
    props.filter.find((option) => option.selected) ||
    ({} as SegmentFilter)
  );
};

const selectedSegment: Ref<SegmentFilter> = ref(getSelectedSegment());
const selectedManagedOption = ref('' as ManagedAdditionalOption | '');
const selectedSubManagerId = ref(0);
const selectedTeams = ref();
const selectedGroups = ref();
const searchTeamQuery = ref('');
const searchGroupQuery = ref('');

const dropdownOpen = ref(false);
const subdropdownOpen = ref(true);
const hoverOption = ref(null as SegmentFilter['segment'] | null);
const subdropdownContainer = ref(null as HTMLElement | null);
const dropdownContainer = ref(null as HTMLElement | null);
const focusOption = ref(null as HTMLElement | null);

const managedSegment = computed(
  () =>
    props.filter.find((option) => option.segment === SegmentOption.MANAGED) || ({} as SegmentFilter)
);
const teamSegment = computed(
  () =>
    props.filter.find((option) => option.segment === SegmentOption.TEAM) || ({} as SegmentFilter)
);
const groupSegment = computed(
  () =>
    props.filter.find((option) => option.segment === SegmentOption.GROUP) || ({} as SegmentFilter)
);

const teams = ref([] as SegmentFilterArrayOption[]);
const subManagers = ref([] as SegmentFilterArrayOption[]);
const searchedTeams = ref([] as SegmentFilterArrayOption[]);
const teamAdditionalOptions = ref([] as SegmentFilter['additional_options']);
const groups = ref([] as SegmentFilterArrayOption[]);
const groupsLoading = ref(false);
const searchedGroups = ref([] as SegmentFilterArrayOption[]);
const includeSubManagers: Ref<boolean | null> = ref(null);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
  if (subdropdownOpen.value) subdropdownOpen.value = !subdropdownOpen.value;
};

const focusSubdropdown = (e: Event) => {
  focusOption.value = e.currentTarget as HTMLElement;
  nextTick(() => {
    subdropdownContainer.value?.querySelector('input')?.focus();
  });
};

const focusDropdown = () => {
  subdropdownOpen.value = false;
  nextTick(() => {
    focusOption.value?.focus();
  });
};

const handleMouseEnter = (option: SegmentFilter) => {
  if (option.segment === 'company') return;
  subdropdownOpen.value = true;
  hoverOption.value = option.segment;
};

const handleMouseLeave = (e: FocusEvent) => {
  const target = e?.relatedTarget as HTMLElement;
  if (!target || !target.closest('.segment-filter-dropdown__sub')) {
    subdropdownOpen.value = false;
    hoverOption.value = null;
  }
};

const selectOption = (option: SegmentFilter) => {
  resetCurrentSelection(SegmentOption.COMPANY);

  if (option.segment === 'company') {
    selectedSegment.value = option;
    dropdownOpen.value = false;
    updateSelected();
  } else {
    subdropdownOpen.value = true;
  }
};
const SegmentOptionsMap = {
  [SegmentOption.TEAM]: {
    value: teams,
    searchedValue: searchedTeams,
  },
  [SegmentOption.GROUP]: {
    value: groups,
    searchedValue: searchedGroups,
  },
  [SegmentOption.MANAGED]: {
    value: subManagers,
  },
};
const setOptions = async (segmentOption: SegmentFilter) => {
  if (!segmentOption.options) return;
  const value = SegmentOptionsMap[segmentOption.segment].value;
  const searchedValue = SegmentOptionsMap[segmentOption.segment].searchedValue;

  if (
    segmentOption.segment === SegmentOption.TEAM &&
    props.teamPage &&
    !!props.initSelectedTeams?.length
  ) {
    const teamId = props.initSelectedTeams[0];
    value.value = segmentOption.options?.filter((team) => team.id === Number.parseInt(teamId));
  } else if (
    segmentOption.segment === SegmentOption.GROUP &&
    props.groupPage &&
    !!props.initSelectedGroups?.length
  ) {
    const groupId = props.initSelectedGroups[0];
    value.value = segmentOption.options?.filter((group) => group.id === Number.parseInt(groupId));
  } else {
    value.value = segmentOption.options || [];
  }
  if (searchedValue) {
    searchedValue.value = value.value;
  }
  if (segmentOption.segment === SegmentOption.TEAM) {
    teamAdditionalOptions.value = segmentOption.additional_options;
  }
};

const resetSegment = ({ set_to_initial_values }: { set_to_initial_values?: boolean } = {}) => {
  selectedSegment.value = getSelectedSegment();
  resetCurrentSelection(SegmentOption.COMPANY, { set_to_initial_values });
  emitSelected();
};

const resetGroups = ({ set_to_initial_values = false }: { set_to_initial_values?: boolean }) => {
  if (set_to_initial_values && props.initSelectedGroups) {
    selectedGroups.value = props.initSelectedGroups;
  } else {
    selectedGroups.value = [];
  }

  searchedGroups.value = groups.value;
  searchGroupQuery.value = '';
};

const resetTeams = ({ set_to_initial_values = false }: { set_to_initial_values?: boolean }) => {
  if (set_to_initial_values && props.initSelectedTeams) {
    selectedTeams.value = props.initSelectedTeams;
  } else {
    selectedTeams.value = [];
  }

  searchedTeams.value = teams.value;
  searchTeamQuery.value = '';

  resetTeamAdditionalOptions({ set_to_initial_values });
};

const resetSubmanagers = ({
  set_to_initial_values = false,
}: {
  set_to_initial_values?: boolean;
}) => {
  if (set_to_initial_values) {
    selectedSubManagerId.value = parseInt(props.initSelectedManager || '0');
    includeSubManagers.value = props.initIncludeSubmanagers;
  } else {
    selectedSubManagerId.value = 0;
    includeSubManagers.value = null;
  }

  selectedManagedOption.value = '';
};

const resetTeamAdditionalOptions = ({
  set_to_initial_values,
}: {
  set_to_initial_values: boolean;
}) => {
  teamAdditionalOptions.value?.forEach((option) => {
    if (option.param === TeamAdditionalOption.INCLUDE_SUB_TEAMS) {
      option.selected = set_to_initial_values ? !!props.initIncludeSubTeams : false;
    }

    if (option.param === TeamAdditionalOption.PRIMARY_TEAM_MEMBERS_ONLY) {
      option.selected = set_to_initial_values ? !!props.initPrimaryTeamMembersOnly : false;
    }
  });
};

const selectManagedOption = ({
  include_submanagers = null,
  managerId = 0,
}: {
  include_submanagers: boolean | null;
  managerId?: number;
}) => {
  resetCurrentSelection(SegmentOption.MANAGED);

  selectedSegment.value = managedSegment.value;
  selectedSubManagerId.value = managerId;
  if (managerId === 0) {
    if (include_submanagers) {
      selectedManagedOption.value = ManagedAdditionalOption.ALL_DIRECTLY_MANAGED;
    } else {
      selectedManagedOption.value = ManagedAdditionalOption.DIRECTLY_MANAGED_USERS;
    }
  }
  emitSelected();
};

const updateSelected = () => {
  let selected = {
    segment: selectedSegment.value.segment,
  } as SegmentParams;
  if (selectedSegment.value.segment === SegmentOption.TEAM) {
    selected.team_ids = selectedTeams.value;
    selected.include_sub_teams = teamAdditionalOptions.value?.find(
      (a) => a.param === TeamAdditionalOption.INCLUDE_SUB_TEAMS
    )?.selected;
    selected.primary_team_members_only = teamAdditionalOptions.value?.find(
      (a) => a.param === TeamAdditionalOption.PRIMARY_TEAM_MEMBERS_ONLY
    )?.selected;
  } else if (selectedSegment.value.segment === SegmentOption.GROUP) {
    selected.advanced_grouping_ids = selectedGroups.value;
  } else if (selectedSegment.value.segment === SegmentOption.MANAGED) {
    if (selectedSubManagerId.value !== 0) {
      selected.manager_id = selectedSubManagerId.value;
    } else if (includeSubManagers.value) {
      selectedManagedOption.value = ManagedAdditionalOption.ALL_DIRECTLY_MANAGED;
      selected.all_directly_managed = true;
    } else {
      selectedManagedOption.value = ManagedAdditionalOption.DIRECTLY_MANAGED_USERS;
      selected.directly_managed_users = true;
    }
  }
  emit('update:modelValue', selected);
};

const emitSelected = _.debounce(updateSelected, 1000);

const search = (value: string, segmentOption: SegmentType) => {
  if (segmentOption === SegmentOption.TEAM) {
    searchedTeams.value = teams.value.filter((option) =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );
  } else if (segmentOption === SegmentOption.GROUP) {
    searchGroups(value);
  }
};

const searchGroups = async (value: string) => {
  if (groupSegment.value.endpoint && groups.value.length === 10) {
    groupsLoading.value = true;
    try {
      const response = await apiClient.get(groupSegment.value.endpoint, {
        params: { q: searchGroupQuery.value },
      });
      if (response.ok) {
        searchedGroups.value = response.data;
      }
    } finally {
      groupsLoading.value = false;
    }
  } else {
    searchedGroups.value = groups.value.filter((option) =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );
  }
};

const searchQuery = _.debounce(search, 500);

const resetCurrentSelection = (
  segmentOption: string,
  { set_to_initial_values }: { set_to_initial_values?: boolean } = {}
) => {
  if (segmentOption !== SegmentOption.MANAGED) {
    resetSubmanagers({ set_to_initial_values });
  }

  if (segmentOption !== SegmentOption.TEAM) {
    resetTeams({ set_to_initial_values });
  }

  if (segmentOption !== SegmentOption.GROUP) {
    resetGroups({ set_to_initial_values });
  }
};

const selectTeams = () => {
  if (selectedTeams.value.length || teamAdditionalOptions.value?.some((a) => a.selected)) {
    resetCurrentSelection(SegmentOption.TEAM);

    if (selectedSegment.value.segment !== SegmentOption.TEAM) {
      selectedSegment.value = teamSegment.value;
    }
  } else {
    resetSegment();
  }
  emitSelected();
};

const selectGroups = () => {
  if (selectedGroups.value.length) {
    resetCurrentSelection(SegmentOption.GROUP);
    if (selectedSegment.value.segment !== SegmentOption.GROUP) {
      selectedSegment.value = groupSegment.value;
    }
  } else {
    resetSegment();
  }
  emitSelected();
};

const dropdown = ref(null);
onClickOutside(dropdown, () => (dropdownOpen.value = false));

watch(
  () => [
    props.initSegment,
    props.initSelectedGroups,
    props.initSelectedTeams,
    props.initIncludeSubTeams,
    props.initPrimaryTeamMembersOnly,
    props.initSelectedManager,
    props.initIncludeSubmanagers,
  ],
  () => {
    resetSegment({ set_to_initial_values: true });
  },
  {
    immediate: true,
  }
);

onMounted(async () => {
  props.filter.forEach((segmentOption) => setOptions(segmentOption));

  window.emitter.on('reset-filters', () => {
    resetSegment({ set_to_initial_values: true });
  });
});
</script>

<template>
  <div
    ref="dropdown"
    class="segment-filter"
    :class="{ 'segment-filter--active': active, 'segment-filter--open': dropdownOpen }"
  >
    <button
      class="data-filter-button"
      :disabled="disabled"
      :aria-expanded="dropdownOpen"
      aria-haspopup="true"
      data-test-id="segment-filter-button"
      @click="toggleDropdown()"
      @keyup.escape="toggleDropdown"
    >
      <span class="filter-label">{{ t('vue_templates.survey_report.segment_filter_label') }} </span
      ><span class="filter-value"> {{ selectedSegment.label }}</span>
      <span
        v-if="selectedSegment.segment === SegmentOption.TEAM && selectedTeams.length > 0"
        class="filter-number"
      >
        {{ selectedTeams.length }}
      </span>
      <span
        v-else-if="selectedSegment.segment === SegmentOption.GROUP && selectedGroups.length > 0"
        class="filter-number"
      >
        {{ selectedGroups.length }}
      </span>
      <span class="data-filter-button__arrow"></span>
    </button>
    <TransitionFade>
      <div
        v-if="dropdownOpen"
        ref="dropdownContainer"
        class="segment-filter-dropdown__main"
        :aria-label="t('vue_templates.survey_report.select_segment')"
        role="listbox"
        tabindex="-1"
        data-test-id="segment-filter-dropdown-main"
      >
        <button
          v-for="(option, index) in filter"
          :key="index"
          class="segment-filter-dropdown__button-item"
          :aria-expanded="subdropdownOpen && hoverOption === option.segment"
          :aria-haspopup="!!option.endpoint"
          role="option"
          :aria-selected="selectedSegment.segment === option.segment"
          :data-test-id="`segment-filter-dropdown-button-${option.segment}`"
          @click.prevent="selectOption(option)"
          @keyup.esc="toggleDropdown"
          @keyup.right="focusSubdropdown($event)"
          @mouseenter="handleMouseEnter(option)"
          @mouseleave="handleMouseLeave"
          @focusin="handleMouseEnter(option)"
          @focusout="handleMouseLeave($event)"
        >
          <span class="option-label">{{ option.label }}</span>
          <span v-if="option.endpoint" class="data-filter-button__arrow"></span>
        </button>

        <TransitionFade>
          <div
            v-if="subdropdownOpen && hoverOption === SegmentOption.MANAGED"
            ref="subdropdownContainer"
            class="segment-filter-dropdown__sub"
            :aria-label="t('vue_templates.data_filter_segment_dropdown.select_managed_users')"
            :aria-expanded="subdropdownOpen"
            aria-haspopup="true"
            role="listbox"
            tabindex="-1"
            data-test-id="segment-filter-dropdown-sub"
            @keyup.left="focusDropdown()"
            @keyup.escape="toggleDropdown"
          >
            <div class="segment-filter-dropdown__sub-item-container">
              <div class="segment-filter-dropdown__header managed-users">
                <legend class="legend--as-label">
                  {{ t('vue_templates.data_filter_segment_dropdown.people_i_manage_label') }}
                </legend>
              </div>
              <fieldset>
                <label
                  :data-test-id="`directly-managed-users`"
                  class="segment-filter-dropdown__label-item"
                >
                  <input
                    :id="ManagedAdditionalOption.DIRECTLY_MANAGED_USERS"
                    v-model="includeSubManagers"
                    :value="false"
                    name="managed-radio"
                    class="button-radio__input"
                    type="radio"
                    @change="selectManagedOption({ include_submanagers: includeSubManagers })"
                  />
                  {{ t('vue_templates.data_filter_segment_dropdown.directly_managed_users') }}
                </label>
                <label
                  :data-test-id="`all-directly-managed`"
                  class="segment-filter-dropdown__label-item"
                >
                  <input
                    :id="ManagedAdditionalOption.ALL_DIRECTLY_MANAGED"
                    v-model="includeSubManagers"
                    :value="true"
                    name="managed-radio"
                    class="button-radio__input"
                    type="radio"
                    @change="selectManagedOption({ include_submanagers: includeSubManagers })"
                  />
                  <span>
                    {{ t('vue_templates.data_filter_segment_dropdown.all_directly_managed') }}
                    <br />
                    <i>{{ t('vue_templates.data_filter_segment_dropdown.including_sub_users') }}</i>
                  </span>
                </label>
              </fieldset>
              <div class="segment-filter-dropdown__header managed-users">
                <legend v-if="subManagers.length > 0" class="legend--as-label">
                  {{ t('vue_templates.data_filter_segment_dropdown.managed_by_submanagers') }}
                </legend>
              </div>
              <fieldset>
                <!-- Submanagers -->
                <label
                  v-for="(option, index) in subManagers"
                  :key="index"
                  :data-test-id="`submanager-${option.id}`"
                  class="segment-filter-dropdown__label-item"
                >
                  <input
                    v-model="selectedSubManagerId"
                    :value="option.id"
                    name="managed-radio"
                    class="button-radio__input"
                    type="radio"
                    @change="
                      selectManagedOption({
                        include_submanagers: includeSubManagers,
                        managerId: selectedSubManagerId,
                      })
                    "
                  />
                  {{ t('vue_templates.data_filter_segment_dropdown.managed_by') }} {{ option.name }}
                </label>
              </fieldset>
              <div class="segment-filter-dropdown__footer">
                <!-- TO DO on next PR -->
                <BaseButton
                  :text="t('vue_templates.survey_report.clear_selection')"
                  button-style="cancel"
                  :disabled="selectedSegment.segment !== SegmentOption.MANAGED"
                  data-test-id="dropdown-sub-clear-selection"
                  :action="resetSegment"
                />
              </div>
            </div>
          </div>
        </TransitionFade>
        <TransitionFade>
          <div
            v-if="subdropdownOpen && hoverOption === SegmentOption.TEAM"
            ref="subdropdownContainer"
            class="segment-filter-dropdown__sub"
            :aria-label="t('vue_templates.survey_report.select_teams')"
            :aria-expanded="subdropdownOpen"
            aria-haspopup="true"
            role="listbox"
            tabindex="-1"
            data-test-id="segment-filter-dropdown-sub"
            @keyup.left="focusDropdown()"
            @keyup.escape="toggleDropdown"
          >
            <div class="segment-filter-dropdown__sub-item-container">
              <div class="segment-filter-dropdown__header">
                <SearchInput
                  v-if="teams.length > 8"
                  v-model="searchTeamQuery"
                  :placeholder="t('vue_templates.teams_list.search_team_label')"
                  :data-test-id="`segment-filter-dropdown-search-${SegmentOption.TEAM}`"
                  @update:modelValue="searchQuery(searchTeamQuery, SegmentOption.TEAM)"
                />
                <template v-if="teamAdditionalOptions?.length">
                  <fieldset>
                    <legend class="visually-hidden">
                      {{ t('vue_templates.survey_report.additional_options') }}
                    </legend>
                    <label
                      v-for="option in teamAdditionalOptions"
                      :key="option.param"
                      :data-test-id="`additional-options-${option.param}`"
                    >
                      <input
                        v-model="option.selected"
                        :value="option.param"
                        type="checkbox"
                        @change="selectTeams"
                      />
                      {{ option.label }}
                    </label>
                  </fieldset>
                  <hr />
                </template>
              </div>
              <template v-if="searchedTeams.length > 0">
                <fieldset>
                  <legend class="visually-hidden">
                    {{ t('vue_templates.survey_report.select_teams') }}
                  </legend>
                  <label
                    v-for="team in searchedTeams"
                    :key="team.id"
                    :data-test-id="`${SegmentOption.TEAM}-${team.id}`"
                  >
                    <input
                      v-model="selectedTeams"
                      :value="team.id"
                      type="checkbox"
                      :disabled="teamPage"
                      @change="selectTeams"
                    />
                    {{ team.name }}
                  </label>
                </fieldset>
              </template>
              <template v-else>
                <span class="segment-filter-dropdown__search-no-results">{{
                  t('helpers.common.no_results_found')
                }}</span>
              </template>
              <div v-if="!teamPage" class="segment-filter-dropdown__footer">
                <BaseButton
                  :text="t('vue_templates.survey_report.clear_selection')"
                  button-style="cancel"
                  data-test-id="dropdown-sub-clear-selection"
                  :disabled="selectedTeams.length === 0"
                  :action="resetSegment"
                />
              </div>
            </div>
          </div>
        </TransitionFade>
        <TransitionFade>
          <div
            v-if="subdropdownOpen && hoverOption === SegmentOption.GROUP"
            ref="subdropdownContainer"
            class="segment-filter-dropdown__sub"
            :aria-label="t('vue_templates.survey_report.select_groups')"
            :aria-expanded="subdropdownOpen"
            aria-haspopup="true"
            role="listbox"
            tabindex="-1"
            data-test-id="segment-filter-dropdown-sub"
            @keyup.left="focusDropdown()"
            @keyup.escape="toggleDropdown"
          >
            <div class="segment-filter-dropdown__sub-item-container">
              <div class="segment-filter-dropdown__header">
                <SearchInput
                  v-if="groups.length > 8"
                  v-model="searchGroupQuery"
                  :placeholder="t('vue_templates.teams_list.search_group_label')"
                  :data-test-id="`segment-filter-dropdown-search-${SegmentOption.GROUP}`"
                  @update:modelValue="searchQuery(searchGroupQuery, SegmentOption.GROUP)"
                />
              </div>
              <template v-if="searchedGroups.length > 0">
                <fieldset>
                  <legend class="visually-hidden">
                    {{ t('vue_templates.survey_report.select_groups') }}
                  </legend>
                  <label v-for="group in searchedGroups" :key="group.id">
                    <input
                      v-model="selectedGroups"
                      :value="group.id"
                      type="checkbox"
                      :disabled="groupPage"
                      @change="selectGroups"
                    />
                    {{ group.name }}
                  </label>
                </fieldset>
              </template>
              <LoadingSpinner v-else-if="groupsLoading" type="small" />
              <template v-else>
                <span class="segment-filter-dropdown__search-no-results">{{
                  t('helpers.common.no_results_found')
                }}</span>
              </template>
              <div v-if="!groupPage" class="segment-filter-dropdown__footer">
                <BaseButton
                  :text="t('vue_templates.survey_report.clear_selection')"
                  button-style="cancel"
                  data-test-id="dropdown-sub-clear-selection"
                  :disabled="selectedGroups.length === 0"
                  :action="resetSegment"
                />
              </div>
            </div>
          </div>
        </TransitionFade>
      </div>
    </TransitionFade>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/_data-filter.scss';
$subdropdown-max-height: 500px;
$dropdown-max-width: $data-filter-button-max-width;
.segment-filter {
  @extend %data-filter-button;
  gap: 0;
  position: relative;

  &--active {
    background: $bubbles;
    border: 1px solid $medium-teal-blue;
  }

  &--open {
    .data-filter-button__arrow {
      transform: rotate(180deg);
    }
  }

  .data-filter-button {
    border-style: none;
    background-color: inherit;

    .filter-number {
      padding: 0px 0.5rem;
      background-color: $medium-teal-blue;
      color: $white;
      min-width: 2rem;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .data-filter-button__arrow {
    @extend %data-filter-dropdown-arrow;
  }
  .segment-filter-dropdown__main {
    left: -15px;
    top: calc(100% + 2px);
    padding: 1px 0;
    .data-filter-button__arrow {
      transform: rotate(-90deg);
      align-self: center;
    }
    .segment-filter-dropdown__button-item {
      padding: 8px 20px;
      border-style: none;
      background-color: $white;
      justify-content: space-between;
      display: flex;

      &:hover,
      &:focus {
        background-color: $platinum;
      }
    }
  }

  .segment-filter-dropdown__sub {
    padding: 8px 0;
    left: calc(100% - 15px);
    top: -1px;

    :deep(.search-input__container) {
      margin: 0 8px;
    }

    hr {
      width: calc(100% - 32px);
      margin-top: 8px;
      margin-bottom: 8px;
      border-color: $platinum;
    }
    .segment-filter-dropdown__footer {
      padding-top: 8px;
      border-top: 1px solid $pastel-blue;
      margin-top: 8px;
      position: sticky;
      bottom: 0;
      background-color: $white;
      button {
        height: 34px;
        width: min-content;
        margin-left: 20px;
      }
    }
    .segment-filter-dropdown__header {
      position: sticky;
      top: 0;
      background-color: $white;
    }

    .segment-filter-dropdown__search-no-results {
      margin: 8px 20px;
    }
    .segment-filter-dropdown__sub-item-container {
      display: flex;
      flex-direction: column;
      max-height: $subdropdown-max-height;
      overflow: auto;

      fieldset {
        display: flex;
        flex-direction: column;
      }

      label {
        margin: 8px 20px;
        font-weight: inherit;
        user-select: none;
        display: flex;
        align-items: flex-start;

        input {
          margin-right: 8px;
          vertical-align: top;
        }
      }

      .segment-filter-dropdown__label-item,
      .segment-filter-dropdown__label-item input {
        cursor: pointer;
      }

      .segment-filter-dropdown__label-item {
        i {
          color: $feldgrau;
        }
      }

      .segment-filter-dropdown__header.managed-users {
        legend {
          font-weight: bold;
          color: var(--button-color);
          padding: 10px 19px 0;
        }
      }
    }
  }

  .segment-filter-dropdown__main,
  .segment-filter-dropdown__sub {
    @extend %data-filter-dropdown-menu;
  }
}
</style>
