import { ref } from 'vue';
import { IApiClient } from '@/types/composables/useApiClient';

interface S3FormData {
  key: string;
  file: File;
  'Content-Type': string;
}

export interface S3HostData {
  formData: S3FormData;
  url: string;
  host: string;
}

interface S3HostDataResponse {
  data: {
    form_data: string;
    url: string;
    host: string;
  };
}

export interface UploadResponse {
  url: string;
  name: string;
  type: string;
  size: number;
}

interface Options {
  allowMultiple?: boolean;
  acceptedExtensions?: string[];
  allowArchives?: boolean;
}

const s3DataEndpoint = '/documents/s3_data.json';
const unsupportedExtensions = [
  'exe',
  'php',
  'rb',
  'msi',
  'bat',
  'sh',
  'js',
  'jar',
  'vb',
  'vbs',
  'dll',
  'sfx',
  'tmp',
  'py',
  'msp',
  'com',
  'rar',
  '7z',
  'gz',
  'html',
];

export function useDirectFileUpload(apiClient: IApiClient, t: I18n['t']) {
  const uploadRequest = ref<XMLHttpRequest>();
  const isUploading = ref(false);
  const uploadProgress = ref(0);
  const isCancelled = ref(false);

  const validateFile = (file: File, options?: Options) => {
    if (!file) throw Error(t('vue_templates.composables.use_direct_file_upload.no_file_provided'));

    const ext = file.name.split('.').pop();
    if (!ext || unsupportedExtensions.includes(ext))
      throw Error(t('vue_templates.composables.use_direct_file_upload.unsupported_file_extension'));
    if (options?.acceptedExtensions?.length && !options.acceptedExtensions.includes(ext)) {
      throw Error(t('vue_templates.composables.use_direct_file_upload.invalid_file_extension'));
    }
  };

  const getS3HostData = async (): Promise<S3HostData | undefined> => {
    const s3Response: S3HostDataResponse = await apiClient.get(s3DataEndpoint);
    const formData = JSON.parse(s3Response.data.form_data);
    const url = s3Response.data.url;
    const host = s3Response.data.host;

    return {
      formData,
      url,
      host,
    };
  };

  const cancelUpload = () => {
    if (uploadRequest.value) {
      try {
        isCancelled.value = true;
        uploadRequest.value.abort();
      } catch (e) {
        //
      } finally {
        isUploading.value = false;
        uploadProgress.value = 0;
      }
    }
  };

  const upload = async (
    s3Data: S3HostData,
    file: File,
    options?: Options
  ): Promise<UploadResponse | undefined> => {
    isCancelled.value = false;
    isUploading.value = true;
    uploadProgress.value = 0;

    if (!s3Data?.formData || !s3Data?.url || !s3Data?.host) {
      throw Error(t('vue_templates.composables.use_direct_file_upload.s3_data_not_available'));
    }

    validateFile(file, options);

    const formData = new FormData();
    Object.keys(s3Data.formData).forEach((key) => {
      formData.append(key, s3Data.formData[key]);
    });
    // Important: the Content-Type must be appended before file otherwise request will fail
    formData.append('Content-Type', file.type);
    formData.append('file', file);

    const response = await apiClient.post(s3Data.url, formData, {
      before(request) {
        uploadRequest.value = request;
      },
      progress(e) {
        if (e.lengthComputable) {
          uploadProgress.value = (e.loaded / e.total) * 100;
        }
      },
    });

    isUploading.value = false;

    if (response.ok) {
      const url = '//' + s3Data.host + '/' + s3Data.formData.key;
      return {
        url,
        name: file.name,
        type: file.type,
        size: file.size,
      };
    }
  };

  return {
    getS3HostData,
    upload,
    isUploading,
    uploadProgress,
    cancelUpload,
    uploadRequest,
    isCancelled,
  };
}
